import React, { useState, useEffect } from 'react';
import { Table, Button, Switch, Modal, Form, Input, InputNumber, Card, Pagination } from 'antd';
import './Donor.css'; 
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import {editDonor, getAllDonorData, SaveData} from '../../../volunteer/apiController';

const { confirm } = Modal;

const Donor = (isAdmin) => {
  const [dataSource, setDataSource] = useState([]);
  const [displayEditModal, setDisplayEditModal] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [showInactive, setShowInactive] = useState(false);
  const [editForm] = Form.useForm(); 
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState();
  const navigate = useNavigate();

  const columns = [
    { title: <span className="columnTitleStyle">S.No</span>, dataIndex: 'id', key: 'id' },
    { title: <span className="columnTitleStyle">Name</span>, dataIndex: 'donor_name', key: 'donor_name' },
    { title: <span className="columnTitleStyle">Mobile No</span>, dataIndex: 'mobile_number', key: 'mobile_number'},
    { title: <span className="columnTitleStyle">Address</span>, dataIndex: 'address', key: 'address'},
    { title: <span className="columnTitleStyle">PAN Details</span>, dataIndex: 'pan_number', key: 'pan_number' },
    {
      title: <span className="columnTitleStyle">Action</span>,
      key: 'action',
      render: (record) => (
        <span>
          <Button type="link" onClick={() => handleEdit(record)}>
            Edit
          </Button>
        </span>
      ),
    },
  ];

  useEffect(() => {
    const payload = {
      page,
      pageSize,
      donorNameOrMobileText:searchText,
    }
    fetchDonorData(payload);
  }, [searchText,page,pageSize]); 


  const fetchDonorData = async (payload) => {
    try {
      const data = await getAllDonorData(payload); 
      console.log("DATA", data);
      // Add a unique key to each donor
      const donorsWithKeys = data.data.data.map((donor, index) => ({ ...donor, key: donor.id || index }));
      setDataSource(data); 
    } catch (error) {
      console.error('Error fetching receipt data:', error);
    }
 };
 
 //To handel Search
  const handleSearch = (value) => {
    setSearchText(value);
  };

  // Action Column Edit
  const handleEdit = (record) => {
    setDisplayEditModal(true);
    setEditedData(record);
    editForm.setFieldsValue(record);
  };
  
  //Edit form Submit
  const handleEditSubmit = () => {
    editForm.validateFields()
      .then(async (values) => {
        const requestBody = {
          donorId : editedData.id,
          donor_name : values.donor_name,
          address : values.address,
          mobile_number:values.mobile_number,
          pan_number:values.pan_number
        }
        console.log(requestBody);
        const responseData = await editDonor(requestBody);
        fetchDonorData({page, pageSize, donorNameOrMobileText: searchText,});
        setDisplayEditModal(false);
        editForm.resetFields();
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  //Edit form Cancel
  const handleEditCancel = () => {
    setDisplayEditModal(false);
    editForm.resetFields();
  };

  //To handle Pagination
  const onShowSizeChange = (current, pageSize) => {
    setPage(current===0?1:current)
    setPageSize(pageSize)
  };

   //To handle Pagination
  const onPageChange = (current, pageSize) => {
    setPage(current===0?1:current)
    setPageSize(pageSize)
  };

  //to go back function
  const toGoBack = () => {
    navigate('/AppLayout',{ state: { isAdmin } });
  }

  return (
    <Card>
    <h2 className='main-heading'>Donor Details</h2>
      <Button type="primary" icon={<LeftOutlined />} style={{ marginBottom: 16 }} onClick={toGoBack}>Back</Button>
      <div>
        <div className='main-component'>
        </div>
        <DonorTable
          dataSource={dataSource}
          columns={columns}  handleEdit={handleEdit} 
          showInactive={showInactive} setShowInactive={setShowInactive}
          searchText={searchText} setSearchText={handleSearch} />

        <EditDonorModal visible={displayEditModal} handleEditSubmit={handleEditSubmit}
          handleEditCancel={handleEditCancel} form={editForm} editedData={editedData} />
      </div>
      <Pagination showSizeChanger 
      current = {page}
      total = {dataSource?.data?.totalItems}
      onChange={ onPageChange}
      onShowSizeChanger = {onShowSizeChange}

    />
    </Card>
 );
};

// Component to display the table
const DonorTable = ({ dataSource, columns, showInactive, setShowInactive, searchText, setSearchText }) => {
  return (
    <div className='container'>
      <div className="switchSection">
        {/* <div>
          <Switch className="switch" checked={!showInactive} 
          onChange={() => setShowInactive(!showInactive)} />
          {!showInactive && <div className='isActiveText'>isActive</div>}
        </div> */}
        <div className='search-bar'>
          <Input 
            placeholder="Search by name or mobile number" 
            value={searchText} 
            onChange={(e) => setSearchText(e.target.value)} 
          />
        </div>
        
      </div>
      <div className="tableContainer">
      {console.log(dataSource)}
    <Table dataSource={dataSource?.data?.data} columns={columns} pagination={false} />
    </div>
    </div>
  );
};

  // Component to Edit Donor
  const EditDonorModal = ({ visible, handleEditSubmit, handleEditCancel, form, editedData }) => {
  return (
    <Modal title="Edit Donor" visible={visible} footer={null} onCancel={handleEditCancel}>
      <Form form={form} layout="vertical" initialValues={editedData}>
              
              <div className="row">
              <div className="col">
                  <Form.Item
                    label="Name"
                    name="donor_name"
                    rules={[{  message: 'Please enter a Name' }]}
                  >
                    <Input className="input-number-wrapper" />
                  </Form.Item>
                </div>
                <div className="col">
                  <Form.Item
                    label="Address"
                    name="address"
                    rules={[{  message: 'Please enter an address' }]}
                  >
                    <Input className="input-number-wrapper" />
                  </Form.Item>
                </div>
              </div>
              <div className="row">
              <div className="col">
                  <Form.Item
                    label="PAN Details"
                    name="pan_number"
                    rules={[{  message: 'Please enter PAN Details' }]}
                  >
                    <Input className="input-number-wrapper" />
                  </Form.Item>
                </div> 
                <div className="col">
                  <Form.Item
                    label="Mobile Number"
                    name="mobile_number"
                    rules={[{  message: 'Please enter Mobile Number' }]}
                  >
                    <Input className="input-number-wrapper" />
                  </Form.Item>
                </div>  
              </div>
              
              <div >
                <Button type="primary" onClick={handleEditSubmit}>
                  Submit
                </Button>
                <Button className='Cancel-button' onClick={handleEditCancel} >
                  Cancel
                </Button>
              </div>
            </Form>
    </Modal>
  );
};

export default Donor;