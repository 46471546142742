import { sendRequest } from "../apiHandler/ApiCall";

// SignUp API:
export async function SignUpApi(data) {
  try {
    let obj = {
      mobile: "9986549365",
      email: "sach123in@gmail.com",
      full_name: "Sach",
      password: "1234",
    };
    const responseData = await sendRequest(
      "post",
      "/api/auth/signup",
      data,
      null
    );
    console.log(responseData);
  } catch (error) {
    console.error("Error:", error);
  }
}

//Login API
export async function LoginApi(data) {
  try {
    const responseData = await sendRequest(
      "post",
      "/api/auth/login",
      data,
      null
    );
    return responseData;
  } catch (error) {
    console.error("Error:", error);
  }
}

/**
 * service list api call
 * @returns service list
 */
export async function ServiceList(payload) {
  try {
    const responseData = await sendRequest(
      "get",
      "api/service/getAllServices",
      null,
      payload
    );
    return responseData;
  } catch (error) {
    console.error("Error:", error);
  }
}

/**
 * events list
 */
export async function EventsList(payload) {
  try {
    const responseData = await sendRequest(
      "get",
      "api/events/getAllEvents",
      null,
      payload
    );
    return responseData;
  } catch (error) {
    console.error("Error:", error);
  }
}

/**
 * save data
 */
export async function SaveData(data) {
  try {
    const responseData = await sendRequest(
      "post",
      "api/receipt/createReceipt",
      data,
      null
    );
    return responseData;
  } catch (error) {
    console.error("Error:", error);
  }
}

/**
 * get receipt details data
 */
export async function getData(payload) {
  try {
    const responseData = await sendRequest(
      "get",
      "api/receipt/getAllReceipt",
      null,
      payload
    );
    return responseData;
  } catch (error) {
    console.error("Error:", error);
  }
}

/**
 * edit receipt details data
 */
export async function updateData() {
  try {
    const responseData = await sendRequest(
      "put",
      "api/receipt/updateReceipt",
      null,
      null
    );
    return responseData;
  } catch (error) {
    console.error("Error:", error);
  }
}
/**
 * get data for Donors
 */
export async function getAllDonorData(payload) {
  try {
    const responseData = await sendRequest(
      "get",
      "api/donors/getAllDonors",
      null,
      payload
    );
    return responseData;
  } catch (error) {
    console.error("Error:", error);
  }
}

/**
 * get data for volunteer
 */
export async function getAllVolunteerData(payload) {
  try {
    const responseData = await sendRequest(
      "get",
      "api/auth/allUsers",
      null,
      payload
    );
    return responseData;
  } catch (error) {
    console.error("Error:", error);
  }
}
/**
 * get data for admin
 */
export async function getAllAdminData() {
  try {
    const responseData = await sendRequest(
      "get",
      "api/auth/allAdmins",
      null,
      null
    );
    return responseData;
  } catch (error) {
    console.error("Error:", error);
  }
}

/**
 * delete service
 */
export async function deleteService(data) {
  try {
    const responseData = await sendRequest(
      "delete",
      `/api/service/deleteService`,
      data,
      null
    );
    return responseData;
  } catch (error) {
    console.log("Error:", error);
  }
}

/**
 * edit service
 */
export async function editService(data) {
  try {
    const responseData = await sendRequest(
      "put",
      "/api/service/updateService",
      data,
      null
    );
    return responseData;
  } catch (error) {
    console.log("Error:", error);
  }
}

/**
 * add service
 */
export async function addService(data) {
  try {
    const responseData = await sendRequest(
      "post",
      "/api/service/createService",
      data,
      null
    );
    return responseData;
  } catch (error) {
    console.log("Error:", error);
  }
}

/**
 * edit donor
 */
export async function editDonor(data) {
  try {
    const responseData = await sendRequest(
      "put",
      "/api/donors/updateDonor",
      data,
      null
    );
    return responseData;
  } catch (error) {
    console.log("Error:", error);
  }
}

/**
 * edit volunteer
 */
export async function editVolunteer(data) {
  try {
    const responseData = await sendRequest(
      "put",
      "/api/auth/changePassword",
      data,
      null
    );
    return responseData;
  } catch (error) {
    console.log("Error:", error);
  }
}

/**
 * delete receipt
 */
export async function deleteReceipt(data) {
  try {
    const responseData = await sendRequest(
      "delete",
      `/api/receipt/deleteReceipt?`,
      data,
      null
    );
    return responseData;
  } catch (error) {
    console.log("Error:", error);
  }
}

/**
 * edit receipt details
 */
export async function editReceiptDetails(data) {
  try {
    const responseData = await sendRequest(
      "put",
      "/api/receipt/updateReceipt",
      data,
      null
    );
    return responseData;
  } catch (error) {
    console.log("Error:", error);
  }
}

/**
 * delete event
 */
export async function deleteEvent(data) {
  try {
    const responseData = await sendRequest(
      "delete",
      `/api/events/deleteEvent`,
      data,
      null
    );
    return responseData;
  } catch (error) {
    console.log("Error:", error);
  }
}

/**
 * edit event
 */
export async function editEvent(data) {
  try {
    const responseData = await sendRequest(
      "put",
      "/api/events/updateEvent",
      data,
      null
    );
    return responseData;
  } catch (error) {
    console.log("Error:", error);
  }
}

/**
 * add event
 */
export async function addEvent(data) {
  try {
    const responseData = await sendRequest(
      "post",
      "/api/events/createEvent",
      data,
      null
    );
    return responseData;
  } catch (error) {
    console.log("Error:", error);
  }
}

/**
 * delete volunteer/user
 */
export async function deleteUser(data) {
  try {
    const responseData = await sendRequest(
      "delete",
      "/api/auth/deleteUser",
      {},
      data
    );
    return responseData;
  } catch (error) {
    console.log("Error:", error);
  }
}

/**
 * get user info from mobile number
 */
export async function GetInfoByMobile(payload) {
  try {
    const responseData = await sendRequest(
      "get",
      "/api/donors/get-donor-details-by-number",
      null,
      payload
    );
    return responseData;
  } catch (error) {
    console.error("Error:", error);
  }
}


/**
 * get receipt pdf
 */
export async function getReceiptPdf(data) {
  try {
    const responseData = await sendRequest(
      "post",
      "/api/receipt-download",
      data,
      null
    );
    return responseData;
  } catch (error) {
    console.log("Error:", error);
  }
}