import React, { useState } from 'react';
import { Form, Input, Button, Avatar, notification } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import OTPInput from 'react-otp-input'; 
import './Login.css';
import Logo from '../Images/Logo11.jpg';
import Axios from 'axios'; 
import { LoginApi } from './apiController';

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');

  const phoneRegex = /^\d{10}$/; // 10-digit phone number

  const handleOtpChange = otp => {
    setOtp(otp);
  };

  const onFinish = async () => {
    if (!phoneRegex.test(phoneNumber)) {
      console.error('Invalid phone number');
      return;
    }

    try {
      setLoading(true);
      let response =  await LoginApi({  mobile:phoneNumber,
        password: otp,
      })
      setLoading(false);
      const isAdmin = response.data?.isAdmin || false;
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('data', JSON.stringify(response.data));
      localStorage.setItem('isadmin',isAdmin);
      console.log('data', response.data)
      if(response.data.token){

        navigate('/AppLayout', { state: { isAdmin } });
      }
    } catch (error) {
      setLoading(false);      
      notification.error({message: "Please contact admin to get access"})
      console.error('Login failed:', error.status);
    }
  };

  return (
    <Form
      name="normal_login"
      className="login-form"
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >
      <Avatar shape="square" size={64} src={Logo} />
      <Form.Item
        name="phoneNumber"
        rules={[
          { required: true, message: 'Please input your Phone Number!' },
          {
            pattern: phoneRegex,
            message: 'Invalid phone number. Please enter 10 digits.',
          },
        ]}
      >
        <Input
          placeholder="Mobile"
          onChange={e => setPhoneNumber(e.target.value)}
        />
      </Form.Item>
      <Form.Item
        name="otp"
        rules={[{ required: true, message: 'Please enter your Passcode!' }]}
      >
        <OTPInput
          numInputs={4}
          separator={<span>-</span>}
          value={otp}
          onChange={handleOtpChange}
         
          isInputNum={true} 
          renderInput={(props, index) => (
            <input {...props} key={index} type="number" style={{ width: '30px', height: '30px',margin: '5px',padding:'5px' }} />
          )}
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          loading={loading}
        >
          Login
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Login;