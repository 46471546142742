import React from 'react';
import { Layout } from 'antd';
import './Topbar.css';

const { Header } = Layout;

const Topbar = () => {
  return (
    <Header className="header">
      <div className="logo">Sree Ramakrishna Bhajana Sabha Trust</div>
    </Header>
  );
};

export default Topbar;
