import React, { useState, useEffect } from 'react';
import { Table, Button, Switch, Modal, Form, Input, InputNumber, Card, Pagination } from 'antd';
import './Events.css'; 
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { addEvent, deleteEvent, editEvent } from '../../../volunteer/apiController';
import { sendRequest } from '../../../apiHandler/ApiCall';

const { confirm } = Modal;

const Events = ({ isAdmin }) => {
  const [dataSource, setDataSource] = useState([]);
  const [displayEditModal, setDisplayEditModal] = useState(false);
  const [displayAddModal, setDisplayAddModal] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [showInactive, setShowInactive] = useState(false);
  const [editForm] = Form.useForm(); 
  const [addForm] = Form.useForm();
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isActive, setIsActive] = useState(1); 
  const navigate = useNavigate();

  useEffect(() => {
    const payload = 
    { 
    page,
    pageSize,
    searchEventName: searchText,
    };
    fetchData(payload);
  }, [page, pageSize,searchText]);

  const fetchData = async (payload) => {
    try {
      
      const response = await sendRequest('GET', 'api/events/getAllEvents', null, payload);
      setDataSource(response.stage);
      console.log(response.stage);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };
  
  const columns = [ 
    { title: <span className="columnTitleStyle">S.No</span>, dataIndex: 'id', key: 'id' },
    { title: <span className="columnTitleStyle">Event Name</span>, dataIndex: 'event_name', key: 'event_name' },
    { title: <span className="columnTitleStyle">Active</span>, dataIndex: 'isActive', key: 'isActive',
    render: (isActive) => (isActive ? 'True' : 'False'), },
    {
       title: <span className="columnTitleStyle">Action</span>,
       key: 'action',
       render: (record) => (
         <span>
           <Button type="link" onClick={() => handleEdit(record)}>
             Edit
           </Button>
           <Button type="link" onClick={() => handleDelete(record)}>
             Delete
           </Button>
         </span>
       ),
    },
  ];

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const handleEdit = (record) => {
    setDisplayEditModal(true);
    setEditedData(record);
    editForm.setFieldsValue(record);
  };

  const handleDelete = (record) => {
    confirm({
      title: 'Confirm Delete',
      content: `Are you sure you want to delete ${record.event_name}?`,
      async onOk() {
        const requestBody = { eventId: record.id };
        try {
          const responseData = await deleteEvent(requestBody);
          fetchData({ page, pageSize, searchEventName: searchText,});
        } catch (error) {
          console.error('Error deleting record:', error);
        }
      },
      onCancel() {},
    });
  };

  const handleEditSubmit = () => {
    editForm.validateFields()
      .then(async (values) => {
        const requestBody = {
          eventId: editedData.id,
          event_name: values.event_name,
          event_date: values.event_date
        }
        console.log(requestBody);
        const responseData = await editEvent(requestBody);
        fetchData({ page, pageSize, searchEventName: searchText,});
        setDisplayEditModal(false);
        editForm.resetFields();
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

    //Edit form Cancel
  const handleEditCancel = () => {
    setDisplayEditModal(false);
    editForm.resetFields();
  };

  const handleAdd = () => {
    setDisplayAddModal(true);
    addForm.resetFields();
  };

  const handleAddSave = async () => {
    try {
      const values = await addForm.validateFields();
      const requestBody = {
        event_name: values.name,
        event_date: values.date
      }
      const responseData = await addEvent(requestBody);
      fetchData({ page, pageSize, searchEventName: searchText,});
      setDisplayAddModal(false);
      addForm.resetFields();
    } catch (error) {
      console.error('Error adding record:', error);
    }
  };

  const handleAddCancel = () => {
    setDisplayAddModal(false);
  };

  const handleSwitchChange = (checked) => {
    setShowInactive(checked);
    setIsActive(checked ? 0 : 1); 
  };

    //For Pagination
  const onShowSizeChange = (current, pageSize) => {
    setPage(current===0?1:current)
    setPageSize(pageSize)
  };

   //For Pagination
  const onPageChange = (current, pageSize) => {
    setPage(current===0?1:current)
    setPageSize(pageSize)
  };

  const toGoBack = () => {
    navigate('/AppLayout', { state: { isAdmin } }); 
  };

  return (
    <Card >
    <h2 className='main-heading'>Events</h2>
      <Button type="primary" icon={<LeftOutlined />} style={{ marginBottom: 16 }} onClick={toGoBack}>Back</Button>
      <div>
        <div className='main-component'></div>
        <EventTable
          dataSource={dataSource} columns={columns} handleAdd={handleAdd} handleEdit={handleEdit}
          handleDelete={handleDelete} showInactive={showInactive} setShowInactive={setShowInactive} 
          searchText={searchText} setSearchText={setSearchText} isActive={isActive}
          setIsActive={setIsActive} handleSwitchChange={handleSwitchChange}
        />

        <EditEventModal visible={displayEditModal} handleEditSubmit={handleEditSubmit}
          handleEditCancel={handleEditCancel} form={editForm} editedData={editedData} />

        <AddEventModal visible={displayAddModal} handleAddSave={handleAddSave}
          handleAddCancel={handleAddCancel} form={addForm} />
      </div>
      <Pagination showSizeChanger 
      current = {page}
      total = {dataSource?.totalItems}
      onChange={ onPageChange}
      onShowSizeChanger = {onShowSizeChange}

    />
    </Card>
  );
};

const EventTable = ({ dataSource, columns, setIsActive, handleAdd, handleEdit, handleDelete, showInactive, setShowInactive, searchText, setSearchText, handleSwitchChange }) => {
//   const filteredDataSource = showInactive
//     ? dataSource.filter((item) => item.isActive === 0) 
//     : dataSource.filter((item) => item.isActive === 1); 

//   const finalDataSource = filteredDataSource?.filter((item) =>
//     item.event_name.toLowerCase().includes(searchText?.toLowerCase())
//   );

  return (
    <div className='container'>
      <div className="switchSection">
        <div>
          <Switch
            checked={showInactive}
            onChange={handleSwitchChange}
            className="switch"
          />
          {!showInactive && <div className='isActiveText'>isActive</div>}
        </div>
        <div className='search-bar'>
          <Input 
            placeholder="Search by Event Name"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <div>
          <Button type="primary" onClick={handleAdd}>
            Add
          </Button>
        </div>
      </div>

      <div className="tableContainer">
      {console.log(dataSource)}
        <Table dataSource={dataSource?.data} columns={columns} pagination={false} />
      </div>
    </div>
  );
};

const AddEventModal = ({ visible, handleAddSave, handleAddCancel, form }) => {
  return (
    <Modal title="Add Event" visible={visible} footer={null} onCancel={handleAddCancel}>
    <Form form={form} layout="vertical" >
      <div className="row">
        <div className="col">
          <Form.Item
            label="Event Name"
            name="name"
            rules={[{ required: true, message: 'Please enter an event name' }]}
          >
            <Input className="input-number-wrapper" />
          </Form.Item>
        </div>
        {/* <div className='col'>
          <Form.Item
            label="Event Date"
            name="date"
            rules={[{  message: 'Please enter an event date' }]}
          >
            <Input className="input-number-wrapper" />
          </Form.Item>
        </div> */}
      </div>
      <Form.Item>
        <Button  type="primary" onClick={handleAddSave}>
          Submit
        </Button>
        <Button  className='Cancel-button' onClick={handleAddCancel} >
          Cancel
        </Button>
      </Form.Item>
    </Form>
  </Modal>
);
};

const EditEventModal = ({ visible, handleEditSubmit, handleEditCancel, form, editedData }) => {
return (
  <Modal title="Edit Event" visible={visible} footer={null} onCancel={handleEditCancel}>
    <Form form={form} layout="vertical" initialValues={editedData}>
      <Form.Item
        label="Event Name"
        name="event_name"
        rules={[{ required: true, message: 'Please enter an event name' }]}
      >
        <Input />
      </Form.Item>
      {/* <Form.Item
        label="Event Date"
        name="event_date"
        rules={[{ required: true, message: 'Please enter an event date' }]}
      >
        <Input />
      </Form.Item> */}
      <Form.Item>
        <Button style={{marginRight:"15px"}} type="primary" onClick={handleEditSubmit}>
          Submit
        </Button>
        <Button onClick={handleEditCancel}>Cancel</Button>
      </Form.Item>
    </Form>
  </Modal>
);
};

export default Events;