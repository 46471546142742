import React, { useState, useEffect } from "react";
import { Table, Button, Card, Form, DatePicker, Pagination, Input, Modal, InputNumber} from "antd";
import "./Reports.css";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { getData } from "../../../volunteer/apiController";

const Reports = (isAdmin) => {
  const [dataSource, setDataSource] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [searchText, setSearchText] = useState();
  const navigate = useNavigate();
  const { RangePicker } = DatePicker;

  const columns = [
    {
      title: <span className="columnTitleStyle">Date</span>,
      dataIndex: "date",
      key: "date",
    },
    {
      title: <span className="columnTitleStyle">Receipt No.</span>,
      dataIndex: "receipt_number",
      key: "receipt_number",
    },
    {
      title: <span className="columnTitleStyle">Name</span>,
      dataIndex: "donor_name",
      key: "donor_name",
    },
    {
      title: <span className="columnTitleStyle">Mobile No</span>,
      dataIndex: "mobile_number",
      key: "mobile_number",
    },
    {
      title: <span className="columnTitleStyle">Donation Type</span>,
      dataIndex: "donation_type",
      key: "donation_type",
    },
    {
      title: <span className="columnTitleStyle">Services Selected</span>,
      dataIndex: "selected_services",
      key: "selected_services",
    },
    {
      title: <span className="columnTitleStyle">Event Name</span>,
      dataIndex: "event_name",
      key: "event_name",
     },
     {
      title: <span className="columnTitleStyle">Amount Collected</span>,
      dataIndex: "paid_amount",
      key: "paid_amount",
      render: (text, record) => {
         if (!text) return '';
         const amount = Number(text);
         if (!isNaN(amount)) {
           const formattedAmount = new Intl.NumberFormat('en-IN').format(amount);
           return `${formattedAmount}`;
         } else {
           return text;
         }
      },
     },
    {
      title: <span className="columnTitleStyle">Action</span>,
      key: "action",
      render: (record) => (
        <span>
       <Button type="link" onClick={() => handleView(record)}>
          View
        </Button>
        </span>
 
      ),
    },
  ];

  useEffect(() => {
    const payload = { 
      page,
      pageSize,
      fromDate,
      toDate,
      searchEventsOrServiceText:searchText };
    fetchReceiptData(payload);
  }, [page, pageSize,fromDate,toDate,searchText]);

  const fetchReceiptData = async (payload) => {
    try {
      
      const data = await getData(payload);
      setDataSource(data);
      console.log("DATA", data.data);
    } catch (error) {
      console.error("Error fetching receipt data:", error);
    }
  };

  //Function to handle view
const handleView = (record) => {
 // Navigate to the Receipt component and pass the selected report's data
 navigate('/Receipt', { state: { receiptData: record } });
 console.log("Data passed to Receipt:", record);
};

  /**
   * api call to trigger get data
   */
  const handleDownload = async () => {
    try {
      const payload = { pageSize: 20 };
      const response = await getData(payload);
      const totalItems = response.data.totalItems;
      if (totalItems > payload.pageSize) {
        payload.pageSize = totalItems + 100;
      }
      const updatedResponse = await getData(payload);
      downloadExcel(updatedResponse.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  
// Helper function to format date as dd-mm-yyyy
const formatDate = (date) => {
  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, '0');
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const year = d.getFullYear();
  return `${day}-${month}-${year}`;
};

//to handle date
const handleDateRangeChange = (dates) => {
  if (dates && dates.length === 2) { 
     const fromDate = formatDate(dates[0]); 
     const toDate = formatDate(dates[1]); 
     setFromDate(fromDate);
     setToDate(toDate);
     setSelectedDateRange(dates);
  } else {
     setFromDate(null);
     setToDate(null);
     setSelectedDateRange([]);
  }
 };
 
  //to generate report
  const handleGenerateReport = () => {
    // Handle generating report with selected date range
    console.log("Selected date range:", selectedDateRange);
  };

  /**
   * download data
   */
  const downloadExcel = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "data.xlsx");
  };

  //For Pagination
  const onShowSizeChange = (current, pageSize) => {
    setPage(current===0?1:current)
    setPageSize(pageSize)
  };

  //For Pagination
  const onPageChange = (current, pageSize) => {
    setPage(current===0?1:current)
    setPageSize(pageSize)
  };

  // to go back function
  const toGoBack = () => {
    navigate("/AppLayout", { state: { isAdmin } });
  };

  return (
    <Card>
    <h2 className='main-heading'>Reports</h2>
      <Button
        type="primary"
        icon={<LeftOutlined />}
        style={{ marginBottom: 16 }}
        onClick={toGoBack}
      >
        Back
      </Button>
      <div>
        <div className="main-component-report">
        <div className="search-field">
            <Input
              placeholder="Search by Event or Service"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <div className="date-picker-container">
            <RangePicker
              format="DD-MM-YYYY"
              placeholder={["Start Date", "End Date"]}
              onChange={handleDateRangeChange}
            />
          </div>
        </div>
        <ReportTable
          dataSource={dataSource}
          columns={columns}
          handleDownload={handleDownload}
        />
      </div>
      <Pagination showSizeChanger 
      current = {page}
      total = {dataSource?.data?.totalItems}
      onChange={ onPageChange}
      onShowSizeChanger = {onShowSizeChange}

    />
    </Card>
  );
};

//Component to display Table and download button
const ReportTable = ({ dataSource, columns, handleDownload }) => {
  return (
    <div className="container">
      <Button
        className="download-button"
        type="primary"
        onClick={() => handleDownload(dataSource)}
      >
        Download
      </Button>
      <Table dataSource={dataSource?.data?.data} columns={columns} pagination={false} />
    </div>
  );
};

export default Reports;
