import React, { useState } from "react";
import {
  Typography,
  Divider,
  List,
  Button,
  Space,
  Row,
  Col,
  Image,
  Form,
  Input,
} from "antd";
import "./Receipt.css";
import Logo from "../../Images/Logo11.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import { RWebShare } from "react-web-share";
import { ToWords } from "to-words";
import { format } from "date-fns";
import moment from "moment";
import { WhatsappIcon, WhatsappShareButton } from "react-share";
import { getReceiptPdf } from "../apiController";
import { BASEURL } from "../../apiHandler/axiosInstance";
// import * as htmlToImage from 'html-to-image';
// import { saveAs } from 'file-saver';
// import nodemailer from 'nodemailer';

const { TextArea } = Input;

//A function to format date
function formatDate(dateString) {
  if (typeof dateString !== "string") {
    console.error("dateString is not a string:", dateString);
    return null;
  }

  const formats = ["DD-MM-YYYY", "MM-DD-YYYY", "DD/MM/YYYY", "YYYY-MM-DD"];
  let date;
  for (const format of formats) {
    date = moment(dateString, format, true);
    if (date.isValid()) {
      break;
    }
  }

  if (!date.isValid()) {
    console.error(`Invalid date format: ${dateString}`);
    return null;
  }

  return date.format("DD-MM-YYYY");
}

const Receipt = () => {
  const location = useLocation();
  const formData = location.state?.receiptData || location.state || {};
  console.log("form", formData);
  const navigate = useNavigate();

  //checking data is structured as expected from DonationDetails
  const isFromDonationDetails =
    formData?.receiptData?.receipt_number !== undefined;
  const receiptData = isFromDonationDetails ? formData?.receiptData : formData;

  const toWords = new ToWords();
  const [pdfUrl, setPdfUrl] = useState("");

  const downloadGeneratedReceipt =async()=>{
    const response = await fetch(`${BASEURL}/api/receipt-download`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    const blob = await response.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));
    setPdfUrl(url);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${formData?.receipt_number || formData?.receiptData?.receipt_number}.pdf`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }

  const fetchGeneratedPdf = async () => {
    
    await downloadGeneratedReceipt();

    const whatsappUrl = `https://api.whatsapp.com/`;
    window.open(whatsappUrl, '_blank');
  };

  const renderAdditionalFields = () => {
    if (
      receiptData?.payment_method === "cheque" ||
      receiptData?.payment_method === "card" ||
      receiptData?.payment_method === "bank_transfer"
    ) {
      return (
        <div id="shareDiv">
          <List.Item className="additional-field">
            <Row justify="space-between" style={{ width: "100%" }}>
              <Col span={11}>
                {receiptData?.payment_method === "card" ||
                receiptData?.payment_method === "bank_transfer"
                  ? `Reference No.:`
                  : `Cheque No.:`}{" "}
              </Col>
              <Col style={{ marginRight: "5px" }} span={12}>
                {receiptData?.reference_num || receiptData?.cheque_num}
              </Col>
            </Row>
          </List.Item>
          <List.Item className="additional-field">
            <Row justify="space-between" style={{ width: "100%" }}>
              <Col span={11}>Bank Name:</Col>
              <Col style={{ marginRight: "5px" }} span={12}>
                {receiptData?.cheque_bank}
              </Col>
            </Row>
          </List.Item>
          <List.Item className="additional-field">
            <Row justify="space-between" style={{ width: "100%" }}>
              <Col span={11}>
                {receiptData?.payment_method === "card" ||
                receiptData?.payment_method === "bank_transfer"
                  ? `Transaction Date:`
                  : `Cheque Date:`}{" "}
              </Col>
              <Col style={{ marginRight: "5px" }} span={12}>
                {formatDate(receiptData?.cheque_date)}
              </Col>
            </Row>
          </List.Item>
        </div>
      );
    } else {
      return null;
    }

    // if (formData?.receiptData?.payment_method=== "cheque") {
    //   return (
    //     <>
    //     <List.Item className="additional-field">
    //       <Row justify="space-between" style={{ width: '100%' }}>
    //         <Col span={11}>Cheque No:</Col>
    //         <Col style={{ marginRight:'5px' }} span={12}>{formData?.receiptData?.chequeNo}</Col>
    //       </Row>
    //     </List.Item>
    //     <List.Item className="additional-field">
    //       <Row justify="space-between" style={{ width: '100%' }}>
    //         <Col span={11}>Bank Name:</Col>
    //         <Col style={{ marginRight:'5px' }} span={12}>{formData?.receiptData?.bankName}</Col>
    //       </Row>
    //     </List.Item>
    //     <List.Item className="additional-field">
    //       <Row justify="space-between" style={{ width: '100%' }}>
    //         <Col span={11}>Cheque Date:</Col>
    //         <Col style={{ marginRight:'5px' }} span={12}>{formData?.receiptData?.chequeDate}</Col>
    //       </Row>
    //     </List.Item>
    //   </>
    //   );
    // } else if (formData?.receiptData?.payment_method === "card") {
    //   return (
    //     <>
    //       <List.Item className="additional-field">
    //         <Row justify="space-between" style={{ width: '100%' }}>
    //           <Col span={11}>{formData?.receiptData?.payment_method == 'card' ||formData?.receiptData?.payment_method == "bank_transfer" ? `Reference No.:` : `Cheque No.:`} </Col>

    //           <Col style={{ marginRight:'5px' }} span={12}>{formData?.receiptData?.reference_num}</Col>
    //         </Row>
    //       </List.Item>
    //       <List.Item className="additional-field">
    //         <Row justify="space-between" style={{ width: '100%' }}>
    //           <Col span={11}>Bank Name:</Col>
    //           <Col style={{ marginRight:'5px' }} span={12}>{formData?.receiptData?.cheque_bank}</Col>
    //         </Row>
    //       </List.Item>
    //       <List.Item className="additional-field">
    //         <Row justify="space-between" style={{ width: '100%' }}>
    //           <Col span={11}>{formData?.receiptData?.payment_method == "card" ||formData?.receiptData?.payment_method == "bank_transfer" ? `Transaction Date:` : `Cheque Date:`} </Col>
    //           <Col style={{ marginRight:'5px' }} span={12}>{formData?.receiptData?.cheque_date}</Col>
    //         </Row>
    //       </List.Item>
    //     </>
    //   );
    // } else if (formData?.receiptData?.payment_method === "bank_transfer") {
    //   return (
    //     <>
    //       <List.Item className="additional-field">
    //         <Row justify="space-between" style={{ width: '100%' }}>
    //           <Col span={11}>Reference No:</Col>
    //           <Col style={{ marginRight:'5px' }} span={12}>{formData?.receiptData?.receipt_number}</Col>
    //         </Row>
    //       </List.Item>
    //       <List.Item className="additional-field">
    //         <Row justify="space-between" style={{ width: '100%' }}>
    //           <Col span={11}>Bank Name:</Col>
    //           <Col style={{ marginRight:'5px' }} span={12}>{formData?.receiptData?.cheque_bank}</Col>
    //         </Row>
    //       </List.Item>
    //       <List.Item className="additional-field">
    //         <Row justify="space-between" style={{ width: '100%' }}>
    //           <Col span={11}>Transaction Date:</Col>
    //           <Col style={{ marginRight:'5px' }} span={12}>{formData?.receiptData?.cheque_date}</Col>
    //         </Row>
    //       </List.Item>
    //     </>
    //   );
    // } else {
    //   return null;
    // }
  };

  // const  shareViaEmail = async () => {
  //   const image = await htmlToImage.toPng(document.getElementById('shareDiv'));
  //   const blob = new Blob([image], { type: 'image/png' });

  //   // Configure nodemailer transporter
  //   let transporter = nodemailer.createTransport({
  //     service: 'Gmail',
  //     auth: {
  //       user: 'devgeeky6969@gmail.com',
  //       pass: 'Sonu@1234'
  //     }
  //   });

  //   // Setup email data
  //   let mailOptions = {
  //     from: 'devgeeky6969@gmail.com',
  //     to: 'sachin.vaidya45@gmail.com',
  //     subject: 'Shared Image',
  //     text: 'Check out this image!',
  //     attachments: [{
  //       filename: 'sharedImage.png',
  //       content: blob
  //     }]
  //   };

  //   // Send email
  //   transporter.sendMail(mailOptions, (error, info) => {
  //     if (error) {
  //       console.log(error);
  //     } else {
  //       console.log('Email sent: ' + info.response);
  //     }
  //   });
  // };

  return (
    <Form layout="vertical" className="receipt-form">
      <Row gutter={20}>
        <Col span={8}>
          <div className="company-details">
            <Image src={Logo} alt="Company Logo" />
          </div>
        </Col>
        <Col span={16}>
          <div className="title-name">
            <h3 style={{ textAlign: "center" }}>
              Sree Ramakrishna Bhajana Sabha Trust (Regd.)
            </h3>
            <p style={{ textAlign: "center" }}>
              15, SHIVA KRUPA, Damodara Mudaliar Street, Halasuru
            </p>
            <p style={{ textAlign: "center" }}>
              Bangalore 560008. Mobile: 9880111024 / 9900793536
            </p>
            <p style={{ marginTop: "-3px", textAlign: "center" }}>
              80G#. ITBA/EXM/S/80G/2018-19/1011015538(1){" "}
            </p>
            <p style={{ textAlign: "center" }}>
              <span style={{ marginLeft: "10px" }}>
                Date: 05-06-2018 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>{" "}
              PAN: AADTS1654F
            </p>
          </div>
        </Col>
      </Row>
      {/* <div className='title-details'>
  
    <div style={{ display: 'flex', justifyContent: 'center', marginTop:'-30px'}}>
  
</div>

  </div> */}
      <Divider />
      <List
        itemLayout="horizontal"
        dataSource={[
          { title: "Receipt No:", description: receiptData?.receipt_number },
          { title: "Date:", description: receiptData?.date },
          {
            title: "Donor Name:",
            description: isFromDonationDetails
              ? formData?.donorDetails?.donor_name
              : receiptData?.donor_name,
          },
          {
            title: "PAN:",
            description: isFromDonationDetails
              ? formData?.donorDetails?.pan_number
              : receiptData?.pan_number,
          },
          { title: "Donation Type:", description: receiptData?.donation_type },
          {
            title: "Amount:",
            description: `${new Intl.NumberFormat("en-IN").format(
              Number(receiptData?.paid_amount)
            )}  (${toWords.convert(Number(receiptData?.paid_amount))} Only)`,
          },
          {
            title: "Payment Method:",
            description: receiptData?.payment_method,
          },
        ]}
        renderItem={(item) => (
          <List.Item>
            <Row justify="space-between" style={{ width: "100%" }}>
              <Col span={11}>{item.title}</Col>
              <Col span={11}>{item.description}</Col>
            </Row>
          </List.Item>
        )}
        className="receipt-list"
      />
      {renderAdditionalFields()}
      <Divider />
      <span>
        This is system generated receipt and does not require signature.
      </span>
      <Space direction="vertical" size="large" className="button-group">
        <Space direction="horizontal" size="large">
          <Button type="primary" onClick={() => window.print()}>
            Print
          </Button>
          {/* <button onClick={() => shareViaEmail()}>Share via Email</button> */}

          <Button type="link" onClick={() => navigate("/AppLayout")}>
            Generate New
          </Button>

          <Button type="primary" onClick={downloadGeneratedReceipt}>
            Download
          </Button>

          {/* <WhatsappShareButton
            url="https://github.com"
            onClick={fetchPdf}
            title="Here is your receipt"
          > */}
            
            <WhatsappIcon onClick={fetchGeneratedPdf}  size={32} round />
          {/* </WhatsappShareButton> */}
        </Space>
      </Space>
    </Form>
  );
};

export default Receipt;
