import React, { useState } from 'react';
import { Form, Avatar } from 'antd';
import { useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import './Mpin.css'; // Import your updated CSS file
import Logo from '../Images/Logo11.jpg';
import Axios from 'axios'; // Import Axios library

const Mpin = ({ phoneNumber }) => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState('');

  const handleOtpChange = async (value) => {
    setOtp(value);
    if (value === '1234') {
      try {
        const response = await Axios.post(' http://hundi-env.eba-rzvakphu.ap-south-1.elasticbeanstalk.com/api/auth/login', {
          phoneNumber,
          otp: value,
        });
        const { token, isAdmin } = response.data;
        localStorage.setItem('token', token);
        navigate('/AppLayout', { state: { isAdmin } });
      } catch (error) {
        console.error('Login failed:', error);
      }
    }
  };

  return (
    <Form name="mpin_form" layout="vertical" className="login-form">
      <Avatar shape="square" size={64} src={Logo} />
      <h3> Enter Passcode </h3>
      <Form.Item className="mpin-form-item">
        <div className="otp-input-container">
          <OtpInput
            numInputs={4}
            separator={<span>-</span>}
            value={otp}
            onChange={handleOtpChange}
            inputStyle={{
              width: '18px',
              height: '18px',
              fontSize: '15px',
              padding: '8px',
              borderRadius: '5px',
              border: '1px solid #ccc',
            }}
            renderInput={(props, index) => (
              <input {...props} key={index} />
            )}
          />
        </div>
      </Form.Item>
    </Form>
  );
};

export default Mpin;
