import React, { useState } from "react";
import { Menu } from "antd";
import {
  HomeOutlined,
  MoneyCollectOutlined,
  FileTextOutlined,
  TeamOutlined,
  AppstoreOutlined,
  LineChartOutlined,
  UserOutlined,
  DownloadOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import "./Sidebar.css";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { getData } from "../../volunteer/apiController";

const { SubMenu } = Menu;

const Sidebar = ({ setSelectedMenu, admin }) => {
  const navigate = useNavigate();
  let userData = localStorage.getItem("data");
  const parsedUserData = JSON.parse(userData);

  const onClickMenu = (id) => {
    setSelectedMenu(id);
    if (id == 9) {
      localStorage.removeItem("token");
      navigate("/");
    } else if (id == 8) {
      navigate("/Profile");
    } else if (id == 7) {
      navigate("/Reports");
    } else if(id == 6){
      navigate("/Events");
    }else if (id == 5) {
      navigate("/Services");
    } else if (id == 4) {
      navigate("/VolunteerDetails");
    } else if (id == 3) {
      navigate("/ReceiptDetails");
    } else if (id == 2) {
      navigate("/Donor");
    } else if (id == 1) {
      navigate("/AppLayout");
    }
  };


  return (
    <>
      <Menu
        theme="dark"
        defaultSelectedKeys={["1"]}
        mode="inline"
        onClick={(event) => onClickMenu(event.key)}
      >
        <Menu.Item key="1" icon={<HomeOutlined />}>
          Home
        </Menu.Item>
        {parsedUserData?.isAdmin==1 && (
          <>
            <Menu.Item key="2" icon={<MoneyCollectOutlined />}>
              Donors
            </Menu.Item>
            <Menu.Item key="3" icon={<FileTextOutlined />}>
              Receipt Details
            </Menu.Item>
            <Menu.Item key="4" icon={<TeamOutlined />}>
              User Details
            </Menu.Item>
            <Menu.Item key="5" icon={<AppstoreOutlined />}>
              Services
            </Menu.Item>
            <Menu.Item key="6" icon={<AppstoreOutlined />}>
              Events
            </Menu.Item>
            <Menu.Item key="7" icon={<LineChartOutlined />}>
              Reports
            </Menu.Item>
          </>
        )}
        <Menu.Item key="8" icon={<UserOutlined />}>
          Profile
        </Menu.Item>
        <Menu.Item key="9" icon={<LogoutOutlined />}>
          Logout
        </Menu.Item>
      </Menu>
    </>
  );
};

export default Sidebar;