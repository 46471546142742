import React, { useState, useEffect } from 'react';
import { Layout, Button } from 'antd';
import Topbar from '../layout/topbar/Topbar';
import Sidebar from '../layout/sidebar/Sidebar';
import Donardetails from '../volunteer/donardetails/Donardetails';
import ReceiptDetails from '../admin/components/receiptdetails/ReceiptDetails';
import Donor from '../admin/components/donor/Donor';
import VolunteerDetails from '../admin/components/volunteerdetails/VolunteerDetails';
import Services from '../admin/components/services/Services';
import Reports from '../admin/components/reports/Reports'


import "./AppLayout.css"
import { useLocation } from 'react-router-dom';

const { Header, Footer, Sider, Content } = Layout;

const AppLayout = () => {
  
  const location = useLocation();
  //const isAdmin = location?.state?.isAdmin;
  //console.log('isAdmin', isAdmin);
  const [collapsed, setCollapsed] = useState(true); 
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 786);
  const [sidebarVisible, setSidebarVisible] = useState(false); 
  const [selectedMenu, setSelectedMenu] = useState('Donordetails');
  const [isAdmin, setIsAdmin] = useState(false);

useEffect(() => {
  setIsAdmin(location?.state?.isAdmin);
}, [location]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    setSidebarVisible(!sidebarVisible); 
  };

  useEffect(() => {
    console.log(location?.state?.isAdmin)
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 786);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Function to render the selected menu component
  const renderMenuComponent = () => {
    switch(selectedMenu) {
      case 'Donor':
        return <Donor />;
      case 'Donardetails':
        return <Donardetails />;
      case 'ReceiptDetails':
        return <ReceiptDetails />;
      case 'VolunteerDetails':
        return <VolunteerDetails />;
      case 'Services':
        return <Services />;
      case 'Reports' :
        return <Reports />;
      default:
        return <Donardetails />;
    }
  };

  return (
    <Layout className='layout'>
      <Header>
        <Topbar />
        {isSmallScreen && ( 
          <Button className='sidebar-toggle' onClick={toggleCollapsed}>
            ☰
          </Button>
        )}
      </Header>
      <Layout>
        {isSmallScreen && sidebarVisible && ( 
          <Sider collapsible collapsed={collapsed} onCollapse={toggleCollapsed}>
            <Sidebar setSelectedMenu={setSelectedMenu} admin = {isAdmin}/>
          </Sider>
        )}
        {!isSmallScreen && ( 
          <Sider>
            <Sidebar setSelectedMenu={setSelectedMenu} admin = {isAdmin} />
          </Sider>
        )}
        <Layout>
        <Content className="content">{renderMenuComponent()}</Content>
          <Footer className='footer'>Digital Hundi App ©2024</Footer>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AppLayout;