import axiosInstance from "./axiosInstance";

export async function sendRequest(method, url, data = null, params = null) {
  try {
    const response = await axiosInstance({
      method,
      url,
      data,
      params
    });
    return response.data;
  } catch (error) {
    throw error.response || error.message;
  }
}


