import React, { useState, useEffect } from 'react';
import { Table, Button, Switch, Modal, Form, Input, InputNumber, Card, Pagination} from 'antd';
import './Services.css'; 
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import {  addService, deleteService, editService } from '../../../volunteer/apiController';
import { sendRequest } from '../../../apiHandler/ApiCall';

const { confirm } = Modal;

const Services = (isAdmin) => {
  const [dataSource, setDataSource] = useState([]);
  const [displayEditModal, setDisplayEditModal] = useState(false);
  const [displayAddModal, setDisplayAddModal] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [showInactive, setShowInactive] = useState(false);
  const [editForm] = Form.useForm(); 
  const [addForm] = Form.useForm();
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState();
  const [isActive, setIsActive] = useState(1); 
  const navigate = useNavigate();

  useEffect(() => {
    const payload = { 
      isActive ,
      page, 
      pageSize,
      serviceNameText:searchText};
    fetchData(payload);
  }, [searchText,page,pageSize,isActive]);

  //isActive state is to fetch active and inactive services when switch is clicked
  const fetchData = async (payload) => {
    try {
      const response = await sendRequest('GET', 'api/service/getAllServices', null, payload);
      setDataSource(response);
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  console.log(dataSource);
  
  const columns = [ 
    { title: <span className="columnTitleStyle">S.No</span>, dataIndex: 'id', key: 'id' },
    { title: <span className="columnTitleStyle">Service Name</span>, dataIndex: 'service_type', key: 'service_type' },
    { title: <span className="columnTitleStyle">Price</span>, dataIndex: 'amount', key: 'amount' },
    {
       title: <span className="columnTitleStyle">Action</span>,
       key: 'action',
       render: (record) => (
        <span>
        <Button type="link" onClick={() => handleEdit(record)}>
          Edit
        </Button>
        {isActive ? (
          <Button type="link" onClick={() => handleDelete(record)}>
            Inactive
          </Button>
        ) : (
          <Button type="link" onClick={() => handleDelete(record)}>
            Active
          </Button>
        )}
      </span>
       ),
    },
  ];

  // Search handler
  const handleSearch = (value) => {
    setSearchText(value);
  };

  // Action Column Edit
  const handleEdit = (record) => {
    setDisplayEditModal(true);
    setEditedData(record);
    editForm.setFieldsValue(record);
  };

  // Action Column Delete
  const handleDelete = (record) => {
    confirm({
      title: isActive ? 'Confirm Inactive' : 'Confirm Active',
      content: `Are you sure you want to ${isActive ? 'Inactive' : 'Active'} ${record.service_type}?`,
      async onOk() {
        const requestBody = { serviceId: record.id };
        try {
          const responseData = await deleteService(requestBody);
          fetchData({ isActive, page, pageSize, serviceNameText: searchText });
        } catch (error) {
          console.error('Error deleting record:', error);
        }
      },
      onCancel() {},
    });
  }

  //Edit form Submit
 const handleEditSubmit = () => {
 editForm.validateFields()
    .then( async (values) => {
      const requestBody = {
        serviceId : editedData.id,
        service_type : values.service_type,
        amount : values.amount
      }
      console.log(requestBody);
      const responseData = await editService(requestBody);
      fetchData({ isActive, page, pageSize, serviceNameText: searchText });
      setDisplayEditModal(false);
      editForm.resetFields();
    })
    .catch((info) => {
      console.log('Validate Failed:', info);
    });
};

  //Edit form Cancel
  const handleEditCancel = () => {
    setDisplayEditModal(false);
    editForm.resetFields();
  };

  //Add button clicked
  const handleAdd = () => {
    setDisplayAddModal(true);
    addForm.resetFields();
  };

  //Add form submit
  const handleAddSave = async () => {
    try {
      const values = await addForm.validateFields();
      const requestBody = {
        service_type: values.name,
        amount: values.price
      }
      const responseData = await addService(requestBody);
      fetchData({ isActive, page, pageSize, serviceNameText: searchText });
      setDisplayAddModal(false);
      addForm.resetFields();
    } catch (error) {
      console.error('Error adding record:', error);
    }
  };

  //Add form cancel
  const handleAddCancel = () => {
    setDisplayAddModal(false);
  };

  //For Pagination
  const onShowSizeChange = (current, pageSize) => {
    setPage(current===0?1:current)
    setPageSize(pageSize)
  };

  //For Pagination
  const onPageChange = (current, pageSize) => {
    setPage(current===0?1:current)
    setPageSize(pageSize)
  };
 
  //to go back Function
  const toGoBack = () => {
    navigate('/AppLayout',{ state: { isAdmin } }); 
  }

  return (
    <Card >
    <h2 className='main-heading'>Services</h2>
     <Button type="primary" icon={<LeftOutlined />} style={{ marginBottom: 16 }} onClick={toGoBack}>Back</Button>
    <div>
      <div className='main-component'></div>
      <ServiceTable
        dataSource={dataSource} columns={columns} handleAdd={handleAdd} handleEdit={handleEdit}
        handleDelete={handleDelete} showInactive={showInactive} setShowInactive={setShowInactive} 
        searchText={searchText} setSearchText={setSearchText} isActive={isActive}
        setIsActive={setIsActive} 
      />

      <EditServiceModal visible={displayEditModal} handleEditSubmit={handleEditSubmit}
        handleEditCancel={handleEditCancel} form={editForm} editedData={editedData} />

      <AddServiceModal visible={displayAddModal} handleAddSave={handleAddSave}
        handleAddCancel={handleAddCancel} form={addForm} />
    </div>
    <Pagination showSizeChanger 
      current = {page}
      total = {dataSource?.data?.totalItems}
      onChange={ onPageChange}
      onShowSizeChanger = {onShowSizeChange}

    />
    </Card>
  );
};

//Component to display Table, Add Button, and Switch
const ServiceTable = ({ dataSource, columns, setIsActive, handleAdd, handleEdit, handleDelete, showInactive, setShowInactive, searchText, setSearchText,isActive }) => {
   //Function to set isActive state
   const handleSwitchChange = async(checked) => {
    setIsActive(checked?1:0); 
    const requestBody = { isActive:checked ,page:1, pageSize:10};
    await sendRequest('GET', 'api/service/getAllServices', null, requestBody);
  };

  return (
    <div className='container'>
      <div className="switchSection">
        <div>
          <Switch
            checked={isActive}
            onChange={handleSwitchChange}
            className="switch"
          />
           <div className='isActiveText'>isActive</div>
        </div>
        <div className='search-bar'>
          <Input 
            placeholder="Search by Service Name "
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <div>
          <Button type="primary" onClick={handleAdd}>
            Add
          </Button>
        </div>
      </div>

      <div className="tableContainer">
    <Table dataSource={dataSource?.data?.data} columns={columns} pagination={false}/>
    </div>
    </div>
  );
};

//Component For Add Button Form
const AddServiceModal = ({ visible, handleAddSave, handleAddCancel, form }) => {
  return (
    <Modal title="Add Service" visible={visible} footer={null} onCancel={handleAddCancel}>
      <Form form={form} layout="vertical" >
        <div className="row">
          <div className="col">
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please enter a name' }]}
            >
              <Input className="input-number-wrapper" />
            </Form.Item>
          </div>
          <div className='col'>
            <Form.Item
              label="Price"
              name="price"
              rules={[{ required: true, message: 'Please enter a price' }]}
            >
              <InputNumber className="input-number-wrapper"/>
            </Form.Item>
          </div>
        </div>
        <Form.Item>
          <Button type="primary" onClick={handleAddSave}>
            Submit
          </Button>
          <Button className='Cancel-button' onClick={handleAddCancel} >
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

// Component for Edit Form
const EditServiceModal = ({ visible, handleEditSubmit, handleEditCancel, form }) => {
  return (
    <Modal title="Edit Service" visible={visible} footer={null} onCancel={handleEditCancel}>
      <Form form={form} layout="vertical">
        <div className="row">
          <div className="col">
          <Form.Item label="Name" name="service_type" rules={[{ required: true, message: 'Please enter a name' }]}>
          <Input />
        </Form.Item>
          </div>
          <div className="col">
          <Form.Item label="Price" name="amount" rules={[{ required: true, message: 'Please enter a price' }]}>
          <Input />
        </Form.Item>
          </div>
        </div>
        <Form.Item>
          <Button style={{marginRight:"15px"}} type="primary" onClick={handleEditSubmit}>
            Submit
          </Button>
          <Button onClick={handleEditCancel}>Cancel</Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};


export default Services;