import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./volunteer/Login";
import Mpin from "./volunteer/Mpin";

import { useRef } from "react";
import Receipt from "./volunteer/receipt/Receipt";
import Donardetails from "./volunteer/donardetails/Donardetails";
import AppLayout from "./layout/AppLayout";
import History from "./admin/components/history/Histroy";
import Sidebar from "./layout/sidebar/Sidebar";
import ReceiptDetails from "./admin/components/receiptdetails/ReceiptDetails";
import Donor from "./admin/components/donor/Donor";
import VolunteerDetails from "./admin/components/volunteerdetails/VolunteerDetails";
import Services from "./admin/components/services/Services";
import Reports from "./admin/components/reports/Reports";
import Profile from "./admin/components/profile/Profile";
import Events from "./admin/components/events/Events";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/Mpin" element={<Mpin />} />
          <Route path="/AppLayout" element={<AppLayout />} />
          <Route path="/Receipt" element={<Receipt />} />
          <Route path="/Donardetails" element={<Donardetails />} />
          <Route path="/history" element={<History />} />
          <Route path="/Donor" element={<Donor />} />
          <Route path="/ReceiptDetails" element={<ReceiptDetails />} />
          <Route path="/VolunteerDetails" element={<VolunteerDetails />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Reports" element={<Reports />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/Events" element={<Events />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
