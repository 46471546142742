import React, { useState, useEffect } from "react";
import { Form, Input, Button, Checkbox, Select, Radio, Row, Col } from "antd";
import "./Donardetails.css";
import Receipt from "../receipt/Receipt";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import {
  EventsList,
  GetInfoByMobile,
  SaveData,
  ServiceList,
} from "../apiController";

const { Option } = Select;

// Define a custom CSS class for styling
const optionClassName = "custom-option";

// CSS to handle text overflow
const customCSS = `
  .${optionClassName} {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const Donardetails = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({
    name: "",
    donationType: "services",
    services: [],
    mobile: "",
    useWhatsapp: false,
    alternativeNo: "",
    email: "",
    address: "",
    pan: "",
    amountCollected: 0,
    paymentMethod: "",
    eventName: "",
    chequeDate: new Date().toISOString().split("T")[0],
    transactionDate: new Date().toISOString().split("T")[0],
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [services, setServices] = useState([]);
  const [events, setEvents] = useState([]);

  const phoneRegex = /^\d{10}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const panRegex = /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/;

  useEffect(() => {
    fetchServices();
    fetchEvents();
  }, []);

  /**
   * fetch the list of services type
   */
  const fetchServices = async () => {
    try {
      const response = await ServiceList({ pageSize: 100 });
      let data = [
        ...response.data.data,
        {
          id: 0,
          service_type: "Others",
          amount: "0",
          isActive: null,
        },
      ];
      setServices(data);
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  /**
   * fetch the list of events type
   */
  const fetchEvents = async () => {
    try {
      const response = await EventsList({ pageSize: 100 });
      setEvents(response);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  /**
   * handle on change of services
   * get calculated total amount and update the collectedAmount
   * @param {*} selectedValues
   */
  const handleServiceChange = async (selectedValues) => {
    let newFormData = { ...formData, ["services"]: selectedValues };
    console.log(selectedValues.length);
    setFormData({ ...formData, services: selectedValues });
    newFormData.amountCollected =
      selectedValues.length > 0
        ? calculateTotalAmount(selectedValues) + (newFormData.customAmount || 0)
        : 0;
    if (!selectedValues.includes("Others")) {
      newFormData.customAmount = 0;
    }
    setFormData(newFormData);
  };

  const handleEventNameChange = async (value) => {
    setFormData({ ...formData, eventName: value });
  };

  /**
   * function to calculate total amount
   * @param {*} selectedServices
   * @returns
   */
  const calculateTotalAmount = (selectedServices) => {
    let totalAmount = 0;

    selectedServices.map((item) => {
      let ServiceAmount =
        services && services.filter((items) => items.service_type == item);
      totalAmount += parseInt(ServiceAmount && ServiceAmount[0].amount);
    });

    return totalAmount;
  };

  const getUserByMobileData = async (data) => {
    console.log("formData",formData);
    try {
      let response = await GetInfoByMobile({ donorMobileText: data });
      console.log("RESSS--->>", response);
      let newFormData = { ...formData, name: response? response?.data?.donor_name:formData?.name,email:response ?response?.data?.email:formData?.email,address:response?response?.data?.address:formData?.address,pan:response ?response?.data?.pan_number:formData?.pan_number};
      // console.log("newFormData:::", newFormData);
      setFormData(newFormData);
      form.setFieldsValue({ name: response? response?.data?.donor_name:formData?.name,email:response ?response?.data?.email:formData?.email,address:response?response?.data?.address:formData?.address,pan:response ?response?.data?.pan_number:formData?.pan_number });
    } catch (error) {
      console.error("Error fetching user by mobile:", error);
    }
  };

  const handleChange = async (value, fieldName) => {
    let newFormData = { ...formData, [fieldName]: value };

    // if (fieldName == "mobile" && value.length == 10) {
    //   await getUserByMobileData(value);
    // }

    if (fieldName === "customAmount") {
      let totalAmount = calculateTotalAmount(newFormData.services);
      newFormData.amountCollected = totalAmount + (value > 0 ? value : 0);
      setFormData(newFormData);
    }

    if (fieldName === "useWhatsapp") {
      if (value && formData.mobile) {
        newFormData = { ...newFormData, alternativeNo: formData.mobile };
      } else {
        newFormData = {
          ...newFormData,
          alternativeNo: value ? formData.mobile : "",
        };
      }
    }

    if (fieldName === "services") {
      const selectedServices = Array.isArray(value) ? value : [value];
      newFormData.services = selectedServices;
    }

    setFormData(newFormData);
  };

  /**
   * submit data
   * @param {*} values
   */
  const handleSubmit = async (values) => {
    console.log("val", values);
    try {
      const {
        name,
        mobile,
        services,
        bankName,
        email,
        address,
        pan,
        paymentMethod,
        amountCollected,
        donationType,
        chequeNo,
        chequeDate,
        referenceNo,
        transactionDate,
        utrNo,
        transferDate,
        transferBankName,
      } = formData;
      const date = new Date().toLocaleDateString();
      console.log(formData, values);
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };
      const response = await SaveData({
        donor_name: name,
        mobile_number: mobile,
        whatsapp_number: mobile,
        email: email,
        address: address,
        pan_number: pan,
        event_name: values.eventName,
        selected_services: donationType == "general" ? null : services,
        payment_method: paymentMethod,
        paid_amount: amountCollected,
        donation_type: donationType,
        reference_num:
          paymentMethod == "bank_transfer" || paymentMethod == "card"
            ? values.referenceNo
            : "",
        cheque_num: values.chequeNo,
        cheque_date:
          paymentMethod == "bank_transfer" || paymentMethod == "card"
            ? transactionDate
            : chequeDate,
        cheque_bank:
          paymentMethod == "bank_transfer" || paymentMethod == "card"
            ? values.cardBankName
            : values.bankName,
      });

      // console.log("Receipt created:", response.stage);
      navigate("/Receipt", { state: { receiptData: response.stage } });
      setFormSubmitted(true);
    } catch (error) {
      console.error("Error creating receipt:", error);
      // Handle error, show error message, etc.
    }
  };

  return (
    <>
      <Form
        layout="vertical"
        className="DonarDetails-form"
        onFinish={handleSubmit}
        form={form}
      >
        <Row gutter={16}>
          <Col xs={24} sm={24} md={8}>
            {console.log("FORMMMM", formData?.name)}
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please input your name!" }]}
            >
              <Input
                value={formData?.name}
                onChange={(e) => handleChange(e.target.value, "name")}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item label="Donation Type">
              <Radio.Group
                name="donationType"
                value={formData?.donationType}
                onChange={(e) => handleChange(e.target.value, "donationType")}
              >
                <Radio.Button value="general">General</Radio.Button>
                <Radio.Button value="services">Services</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
          {formData?.donationType === "services" && (
            <Col xs={24} sm={24} md={8}>
              <Form.Item label="Services" name="services">
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Please select services"
                  value={formData.services}
                  onChange={handleServiceChange}
                >
                  {services &&
                    services?.map((service) => (
                      <Option
                        className={optionClassName}
                        key={service?.id}
                        value={service?.service_type}
                      >
                        {service?.service_type} <br></br>- {service?.amount}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              {formData?.services?.includes("Others") && (
                <Form.Item label="Other Service" name="customAmount">
                  <Input
                    type="number"
                    value={formData.customAmount}
                    onChange={(e) =>
                      handleChange(parseFloat(e.target.value), "customAmount")
                    }
                  />
                </Form.Item>
              )}
            </Col>
          )}
        </Row>

        <Row gutter={16}>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              label="Mobile No."
              name="mobile"
              rules={[
                { required: true, message: "Please input your mobile number!" },
                {
                  pattern: phoneRegex,
                  message: "Invalid phone number. Please enter 10 digits.",
                },
              ]}
            >
              <Input
                type="number"
                onChange={(e) => handleChange(e.target.value, "mobile")}
              />
            </Form.Item>
            <Form.Item
              name="useWhatsapp"
              valuePropName="checked"
              labelAlign="left"
              style={{ marginBottom: 0, border: "none" }}
            >
              <Checkbox
                onChange={(e) => handleChange(e.target.checked, "useWhatsapp")}
              >
                Use as WhatsApp No.
              </Checkbox>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              label="Alternative No."
              name="alternativeNo"
              rules={[
                {
                  pattern: phoneRegex,
                  message: "Invalid phone number. Please enter 10 digits.",
                },
              ]}
            >
              <Input
                type="number"
                value={formData.alternativeNo}
                onChange={(e) => handleChange(e.target.value, "alternativeNo")}
                disabled={formData.useWhatsapp}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { message: "Please input your email address!" },
                {
                  pattern: emailRegex,
                  message: "Invalid email address. Please enter a valid email.",
                },
              ]}
            >
              <Input onChange={(e) => handleChange(e.target.value, "email")} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} sm={24} md={8}>
            <Form.Item label="Address" name="address">
              <Input
                onChange={(e) => handleChange(e.target.value, "address")}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              label="PAN"
              name="pan"
              rules={[
                { message: "Please input your PAN number!" },
                {
                  pattern: panRegex,
                  message: "Invalid PAN number. Please enter a valid PAN.",
                },
              ]}
            >
              <Input onChange={(e) => handleChange(e.target.value, "pan")} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item label="Amount Collected" name="amountCollected">
              {formData.donationType === "general" ? (
                <Input
                  type="number"
                  value={formData.amountCollected}
                  onChange={(e) =>
                    handleChange(parseFloat(e.target.value), "amountCollected")
                  }
                />
              ) : (
                <div>
                  <Input type="number" value={formData.amountCollected} />
                  <p></p>
                </div>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} sm={24} md={8}>
            <Form.Item label="Payment Method" name="paymentMethod">
              <Select
                onChange={(value) => handleChange(value, "paymentMethod")}
              >
                <Option value="cash">Cash</Option>
                <Option value="cheque">Cheque</Option>
                <Option value="card">Card (Debit/Credit)</Option>
                <Option value="bank_transfer">
                  Bank Transfer/NEFT/RTGS/UPI
                </Option>
              </Select>
            </Form.Item>
          </Col>
          {formData.paymentMethod === "cheque" && (
            <>
              <Col xs={24} sm={24} md={8}>
                <Form.Item label="Bank Name" name="bankName">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item label="Cheque No." name="chequeNo">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item label="Cheque Date" name="chequeDate">
                  <Input
                    type="date"
                    defaultValue={new Date().toISOString().split("T")[0]}
                    max={
                      new Date(new Date().setDate(new Date().getDate()))
                        .toISOString()
                        .split("T")[0]
                    }
                    onChange={(e) => handleChange(e.target.value, "chequeDate")}
                  />
                </Form.Item>
              </Col>
            </>
          )}
          {formData.paymentMethod === "card" ||
          formData.paymentMethod === "bank_transfer" ? (
            <>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  label={
                    formData.paymentMethod === "card"
                      ? "Reference No."
                      : "UTR No."
                  }
                  name="referenceNo"
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item label="Transaction Date" name="transactionDate">
                  <Input
                    type="date"
                    defaultValue={new Date().toISOString().split("T")[0]}
                    max={
                      new Date(new Date().setDate(new Date().getDate()))
                        .toISOString()
                        .split("T")[0]
                    }
                    onChange={(e) =>
                      handleChange(e.target.value, "transactionDate")
                    }
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8}>
                <Form.Item label="Bank Name" name="cardBankName">
                  <Input />
                </Form.Item>
              </Col>
            </>
          ) : (
            ""
          )}
          <Col xs={24} sm={24} md={8}>
            <Form.Item label="Event Name" name="eventName">
              <Select onChange={handleEventNameChange}>
                {console.log("event", events)}
                {events?.stage?.data?.map((event) => (
                  <Option key={event?.id} value={event?.event_name}>
                    {event?.event_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Button type="primary" htmlType="submit">
          Generate Receipt
        </Button>
        {formSubmitted && <Receipt formData={formData} />}
      </Form>
    </>
  );
};

export default Donardetails;
