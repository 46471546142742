import React, { useState, useEffect } from "react";
import { Table, Button, Switch, Modal, Form, Input, InputNumber,Card,Pagination, DatePicker } from "antd";
import "./ReceiptDetails.css";
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
// import { format} from 'date-fns';
import moment from 'moment';
import * as XLSX from "xlsx";
import {deleteReceipt, editReceiptDetails, getData} from '../../../volunteer/apiController';

const { confirm } = Modal;

const ReceiptDetails = (isAdmin) => {
  const [dataSource, setDataSource] = useState([]);
  const [showInactive, setShowInactive] = useState(1);
  const [displayEditModal, setDisplayEditModal] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [editForm] = Form.useForm();
  const [searchText, setSearchText] = useState("");
  const [searchField, setSearchField] = useState("");
  const [searchPayment,setSearchPayment] = useState("");
  const [searchBank, setSearchBank] = useState("");
  const [searchCheque,setSearchCheque] = useState("");
  const [searchTransaction, setSearchTransaction] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();

  //Function to format date 
  function formatDate(dateString) {
   if (typeof dateString !== 'string') {
      console.error('dateString is not a string:', dateString);
      return null;
   }
  
   const formats = ['DD-MM-YYYY', 'MM-DD-YYYY', 'DD/MM/YYYY', 'YYYY-MM-DD'];
   let date;
   for (const format of formats) {
      date = moment(dateString, format, true);
      if (date.isValid()) {
        break;
      }
   }
  
   if (!date.isValid()) {
      console.error(`Invalid date format: ${dateString}`);
      return null;
   }
  
   return date.format('DD-MM-YYYY');
  }
  
  const columns = [
    {
      title: <span className="columnTitleStyle">Date</span>,
      dataIndex: "date",
      key: "date",
      render: (text) => {
        console.log(`Rendering date: ${text}`);
        if (!text) return ''; 
        return formatDate(text);
     }
    },
    {
      title: <span className="columnTitleStyle">Receipt No.</span>,
      dataIndex: "receipt_number",
      key: "receipt_number",
    },
    {
      title: <span className="columnTitleStyle">Name</span>,
      dataIndex: "donor_name",
      key: "donor_name",
    },
    {
      title: <span className="columnTitleStyle">Donation Type</span>,
      dataIndex: "donation_type",
      key: "donation_type",
    },
    {
      title: <span className="columnTitleStyle">Services Selected</span>,
      dataIndex: "selected_services",
      key: "selected_services",
    },
    {
      title: <span className="columnTitleStyle">Mobile No</span>,
      dataIndex: "mobile_number",
      key: "mobile_number",
    }, 
    {
      title: <span className="columnTitleStyle">Whatsapp No.</span>,
      dataIndex: "whatsapp_number",
      key: "whatsapp_number",
    },
    {
      title: <span className="columnTitleStyle">Email</span>,
      dataIndex: "email",
      key: "email",
    },
    {
      title: <span className="columnTitleStyle">Address</span>,
      dataIndex: "address",
      key: "address",
    },
    {
      title: <span className="columnTitleStyle">PAN</span>,
      dataIndex: "pan_number",
      key: "pan_number",
    },
    {
      title: <span className="columnTitleStyle">Payment Method</span>,
      dataIndex: "payment_method",
      key: "payment_method",
    },
    {
      title: <span className="columnTitleStyle">Bank Name</span>,
      dataIndex: "cheque_bank",
      key: "cheque_bank",
    },
    {
      title: <span className="columnTitleStyle">Cheque No.</span>,
      dataIndex: "cheque_num",
      key: "cheque_num",
    },
    {
      title: <span className="columnTitleStyle">Reference No.</span>,
      dataIndex: "reference_num",
      key: "reference_num",
    },
    {
      title: <span className="columnTitleStyle">Transaction Date</span>,
      dataIndex: "cheque_date",
      key: "cheque_date",
      render: (text) => {
         console.log(`Rendering date: ${text}`);
         if (!text) return ''; 
         return formatDate(text);
      }
     },
     {
      title: <span className="columnTitleStyle">Amount Collected</span>,
      dataIndex: "paid_amount",
      key: "paid_amount",
      render: (text, record) => {
         if (!text) return '';
         const amount = Number(text);
         if (!isNaN(amount)) {
           const formattedAmount = new Intl.NumberFormat('en-IN').format(amount);
           return `${formattedAmount}`;
         } else {
           return text;
         }
      },
     },
    {
      title: <span className="columnTitleStyle">Cancel Reason</span>,
      dataIndex: "cancel_reason",
      key: "cancel_reason",
    },
    {
      title: <span className="columnTitleStyle">Event Name</span>,
      dataIndex: "event_name",
      key: "event_name",
     },
    {
      title: <span className="columnTitleStyle">Action</span>,
      key: "action",
      render: (record) => (
        <span>
          {showInactive && (
            <>
              <Button type="link" onClick={() => handleEdit(record)}>
                Edit
              </Button>
              <Button type="link" onClick={() => handleDelete(record)}>
                Cancel
              </Button>
            </>
          )}
          {!showInactive && (
            <Button type="link" onClick={() => handleReason(record)}>
              Reason
            </Button>
          )}
        </span>
      ),
    },
  ];

  useEffect(() => {
    const payload = {
      page,
      pageSize,
      searchMobileOrReceiptNumber:searchText,
      searchEventsOrServiceText:searchField,
      searchByPaymentMethod:searchPayment,
      searchByBankName:searchBank,
      searchByChequeOrReferenceNum:searchCheque,
      searchByTransactionDate:searchTransaction,
      isActive:showInactive==true?1:0
    }
    fetchReceiptData(payload);
  }, [searchText, searchField, searchPayment , searchBank , searchCheque ,
    searchTransaction, page,pageSize,showInactive]); 

  const fetchReceiptData = async (payload) => {
    try {
      const data = await getData(payload); 
      setDataSource(data); 
      console.log("DATA", data);
    } catch (error) {
      console.error('Error fetching receipt data:', error);
    }
  };

  //To handle Search
  const handleSearch = (value) => {
    setSearchText(value);
  };

  const handleFielSearch = (value) => {
    setSearchField(value);
  }

  const handlePayment = (value) => {
    setSearchPayment(value);
  }

  const handleBank = (value) => {
    setSearchBank(value);
  }

  const handleCheque = (value) => {
    setSearchCheque(value);
  }

  const handleTransaction = (value) => {
    setSearchTransaction(value);
  }

//Action Column Edit
const handleEdit = (record) => {
  setDisplayEditModal(true);
  setEditedData(record);

  const formatChequeDate = (dateString) => {
     const parsedDate = moment(dateString, "YYYY-MM-DD", true);
     return parsedDate.isValid() ? dateString : moment(dateString, "DD-MM-YYYY").format("YYYY-MM-DD");
  };
 
  const formattedChequeDate = formatChequeDate(record.cheque_date);
  const isAlreadyInFormat = moment(record.date, "YYYY-MM-DD", true).isValid();
const formattedDate = isAlreadyInFormat ? record.date : moment(record.date, "DD-MM-YYYY").format("YYYY-MM-DD");
 
  editForm.setFieldsValue({
     ...record,
     date: formattedDate,
     cheque_date: formattedChequeDate,
  });
 };

// Handle Edit Submit
const handleEditSubmit = () => {
  editForm.validateFields()
     .then( async (values) => {
      console.log(`date in editform is ${values.cheque_date}`);
       const requestBody = {
        receiptId: editedData.id,
        date : values.date,
        mobile_number:values.mobile_number,
        whatsapp_number:values.whatsapp_number,
        donor_name:values.donor_name,
        email:values.email,
        address:values.address,
        pan_number:values.pan_number,
        eventName : values.event_name,
        paymentMethod:values.payment_method,
        paidAmount:values.paid_amount,
        donationType:values.donation_type,
        referenceNum:values.reference_num,
        chequeNum:values.cheque_num,
        chequeDate:values.cheque_date,
        chequeBank:values.cheque_bank,
        selectedServices:values.selected_services,
        isActive:showInactive==true?1:0,
        cancelReason:values.cancel_reason,
       }
       console.log(requestBody);
       const responseData = await editReceiptDetails(requestBody);
       fetchReceiptData({page, pageSize, searchMobileOrReceiptNumber: searchText,
        searchEventsOrServiceText:searchField,
        searchByPaymentMethod:searchPayment,
        searchByBankName:searchBank,
        searchByChequeOrReferenceNum:searchCheque,
        searchByTransactionDate:searchTransaction,  isActive: showInactive ? 1 : 0 });
       setDisplayEditModal(false);
       editForm.resetFields();
     })
     .catch((info) => {
       console.log('Validate Failed:', info);
     });
 };

// Handle Edit Cancel
const handleEditCancel = () => {
  setDisplayEditModal(false);
  editForm.resetFields();
};

// Action Column Delete
const handleDelete = (record) => {
  let cancelReason = ''; 
  
  confirm({
    title: "Confirm Delete",
    content: (
      <div>
        <p>Reason to cancel {record.receipt_number}:</p>
        <Input
          onChange={(e) => (cancelReason = e.target.value)}
          placeholder="Enter reason"
        />
      </div>
    ),
    async onOk() {
      try {
        const requestBody = {
          receiptId: record.id,
          cancel_reason: cancelReason
        };
        console.log(requestBody);
        const responseData = await deleteReceipt(requestBody);
        fetchReceiptData({page, pageSize, searchMobileOrReceiptNumber: searchText,
          searchEventsOrServiceText:searchField,
          searchByPaymentMethod:searchPayment,
          searchByBankName:searchBank,
          searchByChequeOrReferenceNum:searchCheque,
          searchByTransactionDate:searchTransaction,  isActive: showInactive ? 1 : 0 });
      } catch (error) {
        console.error('Error deleting record:', error);
      }
    },
    onCancel() {},
  });
};

//Function to handel Cancel Reason
  const handleReason = (record) => {
    console.log("Cancel Reason:", record.cancel_reason); 
    Modal.info({
      title: "Reason for Cancellation",
      content: <p>{record.cancel_reason}</p>,
      onOk() {},
    });
  };

  //For Pagination
  const onShowSizeChange = (current, pageSize) => {
    setPage(current===0?1:current)
    setPageSize(pageSize)
  };

  //For Pagination
  const onPageChange = (current, pageSize) => {
    setPage(current===0?1:current)
    setPageSize(pageSize)
  };

  //to go back Function
  const toGoBack = () => {
    navigate('/AppLayout',{ state: { isAdmin } });
  }

  const handleDownload = async () => {
    try {
      const payload = {
        pageSize: 20, 
        searchMobileOrReceiptNumber: searchText,
        searchEventsOrServiceText: searchField,
        searchByPaymentMethod: searchPayment,
        searchByBankName: searchBank,
        searchByChequeOrReferenceNum: searchCheque,
        searchByTransactionDate: searchTransaction,
      };
      const response = await getData(payload);
      const totalItems = response.data.totalItems;
      if (totalItems > payload.pageSize) {
        payload.pageSize = totalItems + 100;
      }
      const updatedResponse = await getData(payload);
      downloadExcel(updatedResponse.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const downloadExcel = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "data.xlsx");
  };

  return (
    <Card>
    <h2 className='main-heading'>Receipt Details</h2>
      <Button type="primary" icon={<LeftOutlined />} style={{ marginBottom: 16, marginTop:"10px" }} onClick={toGoBack}>Back</Button>
      <Button type="primary" style={{marginLeft:'85%'}} onClick={() => handleDownload(dataSource)} > Download </Button>
    <div>
      <div className="main-component"></div>
      {console.log("datasrcc", dataSource)}
      <ReceiptTable
        dataSource={ dataSource}
        columns={columns}
        showInactive={showInactive}
        setShowInactive={setShowInactive}
        searchText={searchText}
        setSearchText={handleSearch}
        searchField={searchField}
        setSearchField={handleFielSearch}
        searchBank={searchBank} setSearchBank={handleBank}
        searchPayment={searchPayment} setSearchPayment={handlePayment}
        searchCheque={searchCheque} setSearchCheque={handleCheque}
        searchTransaction={searchTransaction} setSearchTransaction={handleTransaction}
      />
      <EditReceiptDetailsModal visible={displayEditModal} handleEditSubmit={handleEditSubmit}
        handleEditCancel={handleEditCancel} form={editForm} editedData={editedData} />
    </div>
    <Pagination showSizeChanger 
      current = {page}
      total = {dataSource?.data?.totalItems}
      onChange={ onPageChange}
      onShowSizeChanger = {onShowSizeChange}

    />
    </Card>
  );
};

//Component to display Table
const ReceiptTable = ({ dataSource,columns,showInactive,setShowInactive,searchText,setSearchText,
  searchField, setSearchField, searchBank,setSearchBank,searchPayment, setSearchPayment, searchCheque,
  setSearchCheque,searchTransaction,setSearchTransaction,
}) => {

  return (
    <div className="container">
      <div className="switchSection">
        <div>
          <Switch
            checked={showInactive}
            onChange={() => setShowInactive(!showInactive)}
            className="switch"
          />
          <div className="isActiveText">isActive</div>
        </div>
        <div className="search-option">
          <Input
            placeholder="Search by Receipt or Mobile number"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <div className="search-option">
            <Input
              placeholder="Search by Event or Service"
              value={searchField}
              onChange={(e) => setSearchField(e.target.value)}
            />
          </div>
        <div className="search-option">
            <Input
              placeholder="Search by Payment Method"
              value={searchPayment}
              onChange={(e) => setSearchPayment(e.target.value)}
            />
          </div>
        <div className="search-option">
            <Input
              placeholder="Search by Bank Name"
              value={searchBank}
              onChange={(e) => setSearchBank(e.target.value)}
            />
          </div>
        <div className="search-option">
            <Input
              placeholder="Search by Cheque or Reference Number"
              value={searchCheque}
              onChange={(e) => setSearchCheque(e.target.value)}
            />
          </div>
        <div className="search-option">
            <Input
              placeholder="Search by Transaction Date"
              value={searchTransaction}
              onChange={(e) => setSearchTransaction(e.target.value)}
            />
          </div>
      </div>

    <div className="tableContainer">
    <Table dataSource={dataSource?.data?.data} columns={columns} pagination={false}/>
    </div>
    </div>
  );
};

//Component to display Edit Modal
const EditReceiptDetailsModal = ({ visible, handleEditSubmit, handleEditCancel, form , editedData }) => {
  const isAlreadyInFormat = moment(editedData.date, "YYYY-MM-DD", true).isValid();
  const maxDate = isAlreadyInFormat ? editedData.date : moment(editedData.date, "DD-MM-YYYY").format("YYYY-MM-DD");
  console.log('Max Date:',maxDate);
  return (
    <Modal title="Edit Receipt Details" visible={visible} footer={null} onCancel={handleEditCancel}>
      <Form form={form} layout="vertical">
      <div className="row">
      <Form.Item label="Receipt No" name="receipt_number" rules={[{  message: 'Please enter a Recipt No' }]}>
          <Input disabled/>
        </Form.Item>
        <Form.Item label="Date" name="date" rules={[{ message: 'Please enter a Date' }]}>
        <Input type="date"  />
        </Form.Item>

        <Form.Item label="Name" name="donor_name" rules={[{  message: 'Please enter a name' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Donation type" name="donation_type" rules={[{ message: 'Please enter a Donation Type' }]}>
          <Input/>
        </Form.Item>
        <Form.Item label="Mobile No" name="mobile_number" rules={[{ message: 'Please enter a Mobile No' }]}>
          <Input/>
        </Form.Item>
        <Form.Item label="Email	" name="email" rules={[{ message: 'Please enter a Email	.' }]}>
          <Input/>
        </Form.Item>
        </div>
        <Form.Item label="Services Selected" name="selected_services" rules={[{ message: 'Please enter a Services Selected' }]}>
          <Input/>
        </Form.Item>
        <div className="row">
        <Form.Item label="Whatsapp No" name="whatsapp_number" rules={[{ message: 'Please enter a Whatsapp No.' }]}>
          <Input/>
        </Form.Item>
        
        <Form.Item label="Address" name="address" rules={[{ message: 'Please enter a Address.' }]}>
          <Input/>
        </Form.Item>
        <Form.Item label="PAN Number" name="pan_number" rules={[{ message: 'Please enter a PAN Number.' }]}>
          <Input/>
        </Form.Item>
        <Form.Item label="Payment Method" name="payment_method" rules={[{ message: 'Please enter a Payment Method.' }]}>
          <Input/>
        </Form.Item>
        <Form.Item label="Bank Name	" name="cheque_bank" rules={[{ message: 'Please enter a Bank Name	.' }]}>
          <Input/>
        </Form.Item>
        <Form.Item label="Cheque No." name="cheque_num" rules={[{ message: 'Please enter a Cheque No.' }]}>
          <Input/>
        </Form.Item>
        <Form.Item label="Reference No." name="reference_num" rules={[{ message: 'Please enter a Reference No.' }]}>
          <Input/>
        </Form.Item>
        <Form.Item label="Transaction Date" name="cheque_date" rules={[{ message: 'Please enter a Transaction Date' }]}>
          <Input type="date"  max={maxDate}/>
        </Form.Item>
        <Form.Item label="Amount Collected." name="paid_amount" rules={[{ message: 'Please enter an Amount Collected' }]}>
          <Input/>
        </Form.Item>
        <Form.Item label="Cancel Reason" name="cancel_reason" rules={[{ message: 'Please enter a Cancel Reason' }]}>
          <Input/>
        </Form.Item>
        <Form.Item label="Event Name" name="event_name" rules={[{ message: 'Please enter an Event Name' }]}>
          <Input/>
        </Form.Item>
        </div>
        <Form.Item>
          <Button type="primary" style={{marginRight:'10px'}} onClick={handleEditSubmit}>
            Submit
          </Button>
          <Button onClick={handleEditCancel}>Cancel</Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};


export default ReceiptDetails;