import React, { useState, useEffect } from 'react';
import { Card, Avatar, Typography, Button } from 'antd';
import { UserOutlined, ArrowLeftOutlined, LeftOutlined } from '@ant-design/icons';
import './Profile.css';
import { useNavigate } from 'react-router-dom';
import { LoginAPI } from '../../../volunteer/apiController';

const { Title, Paragraph } = Typography;

const Profile = ({ isAdmin, onBack }) => {
  const [profileData, setProfileData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const data = localStorage.getItem('data');
    if (data) {
      const parsedData = JSON.parse(data);
      setProfileData(parsedData);
      console.log('dat1', parsedData);
    }
  }, []);

//to go back Function
  const toGoBack = () => {
    navigate('/AppLayout',{ state: { isAdmin } });
  }
  

  return (
    <div className="profile-container">
      <Card className="profile-card">
      <h2 className='main-heading'>Profile</h2>
      <Button type="primary" icon={<LeftOutlined />} style={{ marginBottom: 16 }} onClick={toGoBack}>Back</Button>
        <div className="profile-header">
          {/* <Button type="text" icon={<ArrowLeftOutlined />} onClick={onBack} className="profile-back-button" /> */}
          <Avatar style={{marginLeft:'0px'}} size={84} icon={<UserOutlined />} />
          <Title className='title' level={4}>{profileData?.full_name}</Title>
        
        <Paragraph className="paragraph">Phone Number: {profileData?.mobile}</Paragraph>
        <Paragraph className="paragraph">Email: {profileData?.email}</Paragraph>
        {isAdmin && (
          <Paragraph className="paragraph">Role: Administrator</Paragraph>
        )}
        </div>
      </Card>
    </div>
  );
};

export default Profile;