import React, { useState, useEffect } from 'react';
import { Table, Button, Switch, Modal, Form, Input, InputNumber, Card,Pagination, Row, Col } from 'antd';
import './VolunteerDetails.css'; 
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import {deleteUser, editVolunteer, getAllVolunteerData, SignUpApi} from '../../../volunteer/apiController';

const { confirm } = Modal;

const VolunteerDetails = (isAdmin) => {
  const [dataSource, setDataSource] = useState([]);
  const [displayEditModal, setDisplayEditModal] = useState(false);
  const [displayAddModal, setDisplayAddModal] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [showInactive, setShowInactive] = useState(1);
  const [editForm] = Form.useForm(); 
  const [addForm] = Form.useForm();
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState();
 
   const navigate = useNavigate();

  const columns = [
    { title: <span className="columnTitleStyle">S.No</span>, dataIndex: 'id', key: 'id' },
    { title: <span className="columnTitleStyle">Name</span>, dataIndex: 'full_name', key: 'full_name' },
    { title: <span className="columnTitleStyle">Email</span>, dataIndex: 'email', key: 'email' },
    
    { title: <span className="columnTitleStyle">Mobile No</span>, dataIndex: 'mobile', key: 'mobile' },
    { title: <span className="columnTitleStyle">Admin</span>, dataIndex: 'isAdmin', key: 'isAdmin',
       render: (isAdmin) => (isAdmin ? 'True' : 'False'), },
    {
      title: <span className="columnTitleStyle">Action</span>,
      key: 'action',
      render: (record) => (
        <span>
          <Button type="link" onClick={() => handleEdit(record)}>
              Change Password
          </Button>
          {showInactive ? (
          <Button type="link" onClick={() => handleDelete(record)}>
            Inactive
          </Button>
        ) : (
          <Button type="link" onClick={() => handleDelete(record)}>
            Active
          </Button>
        )}
        </span>
      ),
    },
  ];

  useEffect(() => {
    const payload = {
      page,
      pageSize,
      searchNameOrMobileText:searchText,
      isActive:showInactive==true?1:0,
    }
    fetchVolunteerData(payload); 
   }, [searchText,page,pageSize,showInactive]);

  const fetchVolunteerData = async (payload) => {
    try {
      const data = await getAllVolunteerData(payload); 
      setDataSource(data.data); 
      console.log("DATA", data?.data);
    } catch (error) {
      console.error('Error fetching receipt data:', error);
    }
  };

  //Function to handle Search
  const handleSearch = (value) => {
    setSearchText(value);
    setPage(1);
  };

  // Action Column Edit
  const handleEdit = (record) => {
    setDisplayEditModal(true);
    setEditedData(record);
    editForm.setFieldsValue(record);
  };

  // Action Column Delete
  const handleDelete = (record) => {
    confirm({
      title: showInactive ? 'Confirm Inactive' : 'Confirm Active',
      content: `Are you sure you want to ${showInactive ? 'Inactive' : 'Active'} ${record.full_name}?`,
      async onOk() {
        const requestBody = { userId: record.id };
        try {
          const responseData = await deleteUser(requestBody);
          fetchVolunteerData({page, pageSize, searchNameOrMobileText: searchText,
            isActive: showInactive ? 1 : 0,});
        } catch (error) {
          console.error('Error deleting record:', error);
        }
      },
      onCancel() {},
    });
  }

  //Edit form Submit
  const handleEditSubmit = () => {
    editForm.validateFields()
      .then(async (values) => {
       const requestBody = {
        userId:editedData.id,
        newPassword:values.newPassword
       }
       console.log(requestBody);
       const responseData = await editVolunteer(requestBody);
       fetchVolunteerData({page, pageSize, searchNameOrMobileText: searchText,
        isActive: showInactive ? 1 : 0,});
        setDisplayEditModal(false);
        editForm.resetFields();
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  //Edit form Cancel
  const handleEditCancel = () => {
    setDisplayEditModal(false);
    editForm.resetFields();
  };

  //Add button clicked
  const handleAdd = () => {
    setDisplayAddModal(true);
    addForm.resetFields();
  };

  //Add form submit
  const handleAddSave = async () => {
    try {
      const values = await addForm.validateFields();
      const isAdminValue = values.isAdmin ? 1 : 0;
      const requestBody = {
        mobile: values.mobile,
        email: values.email,
        full_name: values.full_name,
        password: values.password,
        isAdmin: isAdminValue,
      }
      console.log(requestBody);
      const responseData = await SignUpApi (requestBody);
      fetchVolunteerData({page, pageSize, searchNameOrMobileText: searchText,
          isActive: showInactive ? 1 : 0,});
      setDisplayAddModal(false);
      addForm.resetFields();
    } catch (error) {
      console.error('Error adding record:', error);
    }
  };

  //Add form cancel
  const handleAddCancel = () => {
    setDisplayAddModal(false);
  };

  //Function to set isActive state
  const handleSwitchChange = (checked) => {
    setShowInactive(checked);
  };

  //For Pagination
  const onShowSizeChange = (current, pageSize) => {
    setPage(current===0?1:current)
    setPageSize(pageSize)
  };

   //For Pagination
  const onPageChange = (current, pageSize) => {
    setPage(current===0?1:current)
    setPageSize(pageSize)
  };

  // to go back function
  const toGoBack =()=>{
    navigate('/AppLayout',{ state: { isAdmin } });
  };

  return (
    <Card>
    <h2 className='main-heading'>User Details</h2>
     <Button type="primary" icon={<LeftOutlined />} style={{ marginBottom: 16 }} onClick={toGoBack}>Back</Button>
   {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
 <Button type="primary" icon={<LeftOutlined />} style={{ marginBottom: 16 }} onClick={toGoBack}>Back</Button>
 <h2 style={{ textAlign: 'center', margin: '0 auto' }}>User Details</h2>
</div> */}

      
    <div>
      <div className='main-component'>
      </div>
      <VolunteerTable
        dataSource={dataSource}
        columns={columns} handleAdd={handleAdd} handleEdit={handleEdit} handleDelete={handleDelete}
        showInactive={showInactive} setShowInactive={setShowInactive}
        searchText={searchText} setSearchText={handleSearch} handleSwitchChange={handleSwitchChange}
      />
  
      <EditVolunteerModal visible={displayEditModal} handleEditSubmit={handleEditSubmit}
        handleEditCancel={handleEditCancel} form={editForm} editedData={editedData} />

      <AddVolunteerModal visible={displayAddModal} handleAddSave={handleAddSave}
        handleAddCancel={handleAddCancel} form={addForm} />
    </div>
    <Pagination showSizeChanger 
      current = {page}
      total = {dataSource?.totalItems}
      onChange={ onPageChange}
      onShowSizeChanger = {onShowSizeChange}

    />
    </Card>
  );
};

const VolunteerTable = ({ dataSource, columns, handleAdd, handleEdit, handleDelete, showInactive, setShowInactive, searchText, setSearchText,handleSwitchChange }) => {

  return (
    <div className='container'>
      <div className="switchSection">
        <div>
          <Switch
            checked={showInactive}
            onChange={handleSwitchChange}
            className="switch"
          />
         <div className='isActiveText'>isActive</div>
        </div>
        <div className='search-bar'>
          <Input 
            placeholder="Search by name or mobile number"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <div >
          <Button type="primary" onClick={handleAdd}>
            Add
          </Button>
        </div>
      </div>

    <div className="tableContainer">
    {console.log(dataSource)}
    <Table dataSource={dataSource.data} columns={columns} pagination={false} />
    </div>
    </div>
  );
};

const AddVolunteerModal = ({ visible, handleAddSave, handleAddCancel, form }) => {
  return (
    <Modal title="Add User" visible={visible} footer={null} onCancel={handleAddCancel}>
      <Form form={form} layout="vertical" >
        <div className="row">
        <div className='col'>
        <Form.Item
          label="Mobile No"
          name="mobile"
          rules={[{ required: true, message: 'Please enter a mobile number' }]}
        >
          <InputNumber className="input-number-wrapper"/>
        </Form.Item>
        </div>
        <div className="col">
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: 'Please enter a Email' }]}
            >
              <Input className="input-number-wrapper" />
            </Form.Item>
          </div>
          </div>
          <div className="row"> 
          <div className="col">
            <Form.Item
              label="Name"
              name="full_name"
              rules={[{ required: true, message: 'Please enter a name' }]}
            >
              <Input className="input-number-wrapper" />
            </Form.Item>
          </div>
        {/* <div className="col">
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Please enter a password' }]}
            >
              <Input className="input-number-wrapper" />
            </Form.Item>
          </div> */}
          </div>
          <div className="row">
          <div className="col">
            <Form.Item
              label="isAdmin"
              name="isAdmin"
              valuePropName="checked" 
            >
              <Switch />
            </Form.Item>
          </div>
        </div>
        <Form.Item>
          <Button type="primary" onClick={handleAddSave}>
            Submit
          </Button>
          <Button className='Cancel-button' onClick={handleAddCancel} >
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const EditVolunteerModal = ({ visible, handleEditSubmit, handleEditCancel, form, editedData }) => {
  return (
    <Modal title="Edit User" visible={visible} footer={null} onCancel={handleEditCancel}>
      <Form form={form} layout="vertical" initialValues={editedData} >
              <div className="row">
          <div className="col">
            <Form.Item
              label="New Password"
              name="newPassword"
              rules={[{ required: true, message: 'Please enter new password' }]}
            >
              <Input className="input-number-wrapper" />
            </Form.Item>
          </div>
        </div>
        <Form.Item>
          <Button type="primary" onClick={handleEditSubmit}>
            Submit
          </Button>
          <Button className='Cancel-button' onClick={handleEditCancel} >
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default VolunteerDetails;